import React, { useState} from 'react';
import Heart from '../assets/heart.svg';

import DropdownSelect from './dopdownselect/dropdownselect'
import AppIcon from './AppIcon'

import Logo1 from "../assets/logo1.png"
import Logo2 from "../assets/logo2.png"

const HabitPicker = ( props ) => {

    return (

        <div className="m-auto w-11/12 max-w-3xl min-w-5/6 bg-white shadow-lg rounded text-center">
            <p className="pt-6 px-6 font-montserrat text-gray-900">Select Apps to compare</p>
            
            <div className="mx-8 mt-2 pickerLayout pb-10">
                <div className="flex-1 flex flex-col justify-around items-center w-full">  
                  <AppIcon className="m-auto p-6 w-40 h-40" app={props.app1} />
                  <DropdownSelect options={props.list} selectedOption={props.app1} selectHandler={props.app1Handler}/>
                </div>

                <p className="w-16 font-montserrat font-light text-4xl text-gray-900 self-center">vs</p>

                <div className="flex-1 flex flex-col justify-around items-center w-full">  
                    <AppIcon className="m-auto p-6 w-40 h-40" app={props.app2} />
                    <DropdownSelect options={props.list} selectedOption={props.app2} selectHandler={props.app2Handler}/>
                </div>
            </div>
            
        </div>
    )

}

export default HabitPicker;

