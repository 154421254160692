import React from "react"
import Layout from './layout/layout'
import HabitPicker from './habitPicker'
import "../css/tailwind.css"
import {  graphql } from "gatsby"

export default class Home extends React.Component {

  constructor({ pageContext }) {
    super({ pageContext });
    
    const appList = [...pageContext.apps];

    let snapHabit = appList.find(e => e.App === "SnapHabit" );

    this.state = {
      app1: snapHabit, 
      app2: null, 
      list: appList
    };

  }

  app1Handler = (app) => {
    console.log(app);
    const newState = {...this.state};
    newState.app1 = app;
    this.setState(newState);
    this.checkApps(app, this.state.app2);
  }

  app2Handler = (app) => {
    console.log(app);
    const newState = {...this.state};
    newState.app2 = app;
    this.setState(newState);
    this.checkApps(this.state.app1, app);
  }

  checkApps = (app1, app2) => {
    if((app1 && app2) && (app1 !== app2)) {
      window.location = app1.App + "-vs-" + app2.App
    }
  }


  render() {
    return (
      <Layout>
        <HabitPicker
          app1={this.state.app1} 
          app2={this.state.app2} 
          list={this.state.list} 
          app1Handler={this.app1Handler}
          app2Handler={this.app2Handler} />
      </Layout>
    )
  }

  
}
