import React, { useState, useEffect, useRef } from 'react';
import Logo from "../../assets/logo2.png"
import AppIcon from '../AppIcon'

const DropdownSelect = ( props ) => {

    //Outside clicks should close the menu
    const node = useRef();
    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
      }, []);

    const handleClick = e => {
        if (node.current.contains(e.target)) {         
            // inside click
            return;
        }
        
        setOpen(false);
    };

    const [isOpen, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selectedOption);

    const didSelectOption = ( option ) => {
        setOpen(false);
        setSelectedOption(option);
        props.selectHandler(option);
    }

    const menu = (
        <ul className="customDropdown-menu text-gray-700">
            {
                props.options.map( (app) => {
                    return (
                        <li className="hover:bg-gray-200 cursor-pointer p-2 flex justify-start align-baseline text-left" 
                            onClick={() => didSelectOption(app)}>
                            <AppIcon className="w-8 h-8" app={app} />
                            <div className="pl-2 self-center">{app.App}</div>
                        </li>
                    )
                })
            }
        </ul>
    )

    return (
        <div ref={node} className="relative max-w-full font-body">
            <button 
                className="hover:bg-gray-200 rounded flex justify-center w-full text-gray-900 font-semibold text-xl p-2" 
                onClick={() => setOpen(!isOpen)}>

                <div className="ml-6">{selectedOption ? selectedOption.App : 'Choose an app'}</div>
                <svg className={(isOpen ? "rotate180 " : "") + "w-8 self-center text-gray-600"} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 6.5L8 10.5L3.5 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
                </svg>
            </button>
            {isOpen ? menu : ''}
        </div>
    )

}

export default DropdownSelect;

